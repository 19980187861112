import { FaInstagram, FaEtsy } from "react-icons/fa";
export const SocialIcons = (props: { color: string }) => {
  return (
    <div className="flex gap-3">
      <a
        href="https://www.etsy.com/shop/DianaWayDesigns"
        target="__blank"
        referrerPolicy="no-referrer"
      >
        <FaEtsy className={`cursor-pointer ${props.color} hover:opacity-80`} />
      </a>
      <a
        href="https://www.instagram.com/dianawaydesigns/"
        target="__blank"
        referrerPolicy="no-referrer"
      >
        <FaInstagram
          className={`cursor-pointer ${props.color} hover:opacity-80`}
        />
      </a>
    </div>
  );
};
