import { FaEtsy } from "react-icons/fa";
import "./App.css";

import Banner from "./assets/images/banners/Diana Way designs banner.png";
import Tulips from "./assets/images/banners/dwd-tulips.png";
import { SocialIcons } from "./components/social-icons";

function App() {
  return (
    <div className="bg-neutral-50  min-h-screen font-thin font-sans ">
      <header className="bg-dwd-gold-100 h-20 border-b-2 static border-dwd-gold-500  text-dwd-gold-800  px-1 flex    items-center  text-center   capitalize text-2xl  ">
        <div className="flex gap-2 w-full justify-between items-center max-w-7xl mx-auto">
          <div className="flex  gap-1 items-end">
            <div className="bg-white border-dwd-gold-400 max-w-fit border rounded-xl">
              <img src={Tulips} className="h-12 max-w-fit" alt="dwd-tulips" />
            </div>
            <div className="uppercase text-lg md:text-2xl">
              diana way designs
            </div>
          </div>
          <SocialIcons color="fill-dwd-gold-500" />
        </div>
      </header>
      <div className="[min-height:calc(100vh-5rem)] h-full overflow-auto ">
        <div className="w-full bg-dwd-blue-600">
          <div className=" flex flex-col gap-3 h-full mx-auto p-2">
            <div className="w-fit  rounded-xl ">
              <img
                src={Banner}
                alt="diana-way-banner"
                className="rounded-xl "
              />
            </div>
          </div>
        </div>
        <div className="h-screen flex gap-2 flex-col max-w-7xl mx-auto p-2">
          <section className="flex flex-col items-center text-sm sm:flex-row gap-2 sm:justify-center sm:text-lg font-light tracking-wide bg-dwd-orange-400 text-dwd-gold-100 rounded-xl  w-full py-4 ">
            <b className="font-bold tracking-normal whitespace-nowrap">
              Looking for custom designs?
            </b>{" "}
            Reach out to me on my
            <a
              href="https://www.etsy.com/shop/DianaWayDesigns"
              target="__blank"
              referrerPolicy="no-referrer"
              className="font-semibold border border-dwd-orange-50 rounded px-2  flex gap-1 items-center active:text-dwd-orange-700 hover:text-dwd-orange-500 hover:bg-dwd-orange-50"
            >
              <FaEtsy className="" />
              Etsy Store.
            </a>
            See you soon!
          </section>
          <section className="flex flex-col border border-dwd-blue-500 h-2/4 items-center justify-center sm:items-center text-2xl px-2 py-3 uppercase font-semibold  text-dwd-blue-600 rounded-lg  gap-1 w-full  tracking-wide">
            <div className="text-center">
              Follow and join the <b className="text-dwd-blue-600">D.W.D.</b>
              community
            </div>
            <SocialIcons color="fill-dwd-blue-500 hover:fill-dwd-blue-600" />
          </section>
        </div>
      </div>
      <footer className="h-40 bg-dwd-green-700 border-t-2 border-dwd-green-900 text-dwd-green-200 px-2 py-3 ">
        <div className="max-w-7xl text-xl flex justify-between items-center gap-3 h-full mx-auto p-2 ">
          <SocialIcons color="fill-neutral-50" />
          <div className="flex gap-1">
            @{new Date().getFullYear()}
            <b>Diana Way Designs.</b>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
